<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<!-- 大表单 -->
		<div class="form-body" style="padding-top: 80rem">
			<el-form ref="form" :model="form" :rules="rules" label-width="150rem" @submit.native.prevent="" @enter="()=>{}">
				<el-form-item label="任务周期名称" prop="name">
					<el-input v-model="form.name" placeholder="请输入任务周期名称"></el-input>
				</el-form-item>
				<el-form-item label="任务周期时间段" required="" style="width: 500rem">
					<el-col :span="11">
						<el-form-item prop="start_at">
							<el-date-picker v-model="form.start_at" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择开始日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="2" style="text-align: center"> - </el-col>
					<el-col :span="11">
						<el-form-item prop="end_at">
							<el-date-picker v-model="form.end_at" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item label="适用校区" prop="school_ids">
					<el-select v-model="form.school_ids" multiple="" placeholder="请选择校区">
						<el-option v-for="item in schoolArr" :key="item.id" :label="item.school_name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<h4>设置可辅导时间</h4>
				<div class="container">
					<el-row v-for="(item, index) in form.time_data" style="margin-top: 20rem">
						<el-col :span="20" class="container-align">
							<el-form-item label="时间段名称">
								<el-input v-model="form.time_data[index].name" placeholder="请输入时间段名称" class="form_item"></el-input>
							</el-form-item>
							<el-form-item label="选择时间">
								<el-time-select :popper-append-to-body="false" placeholder="起始时间" v-model="form.time_data[index].start_time" :picker-options="{
										start: '00:00',
										step: '00:05',
										end: '23:55'
									}" value-format="HH:mm:ss" class="form_item" @change="StartTime(index)">
								&gt;
								</el-time-select>
								<span style="margin: 0 14rem">至</span>
								<el-time-select placeholder="结束时间" :popper-append-to-body="false" v-model="form.time_data[index].end_time" :picker-options="{
										start: '00:00',
										step: '00:05',
										end: '23:55',
									}" class="form_item" @change="EndTime(index)">
								&gt;
                <!-- minTime: form.time_data[index].start_time -->
								</el-time-select>
							</el-form-item>
						</el-col>
						<el-col :span="1" style="color: #fff">1111 </el-col>
						<el-col :span="3" style="padding-top: 20rem; display: flex; margin: auto">
							<el-button @click.prevent="deleteform(index)" v-if="form.time_data.length > 1">删除</el-button>
							<el-button @click.prevent="addform" type="primary" v-if="form.time_data.length == index + 1">添加</el-button>
						</el-col>
					</el-row>
				</div>
			</el-form>
		</div>
		<!-- 底部按钮 -->
		
	
</edit-template>
</template>

<script>
	export default {
	_config:{"route":{"path":"add","meta":{"title":"新增","type":0}}},
  data() {
			let validateDate = (rule, value, callback) => {
				let { start_at, end_at } = this.form
				if (!!start_at && !!end_at) {
					let start = new Date(start_at).getTime()
					let end = new Date(end_at).getTime()
					if (start >= end) {
						callback(new Error('开始时间必须小于结束时间'))
					} else callback()
				} else callback()
			}
			return {
				form: {
					school_ids: [],
					time_data: [
						{
							name: '',
							start_time: '',
							end_time: ''
						}
					]
				},
				campusArr: [],
				schoolArr: [],
				rules: {
					name: [{ required: true, message: '请输入任务周期名称', trigger: 'blur' }],
					school_ids: [{ required: true, message: '请选择校区', trigger: 'change' }],
					start_at: [
						{ required: true, type: 'string', message: '请选择开始时间' },
						{ validator: validateDate, trigger: 'change' }
					],
					end_at: [
						{ required: true, message: '请选择结束时间', type: 'string' },
						{ validator: validateDate, trigger: 'change' }
					]
				}
			}
		},

		methods: {
			save(form) {
				this.$refs[form].validate(valid => {
					if (valid) {
						for (let index = 0; index < this.form.time_data.length; index++) {
							if (this.form.time_data[index].name === '') {
								return this.$message.error('时间段名称不能为空')
							}
							if (this.form.time_data[index].start_time === '') {
								return this.$message.error('时间段不能为空')
							}
							if (this.form.time_data[index].end_time === '') {
								return this.$message.error('时间段不能为空')
							}
							if (this.timeConversion(this.form.time_data[index].end_time) - this.timeConversion(this.form.time_data[index].start_time) < 30) {
								return this.$message.error('辅导时间不可小于30分钟')
							}
						}

						this.$_axios2.post('api/1v1/task-cycle', this.form).then(res => {
              if (res.data.status === 0) {
                this.$message({
                  type: 'success',
                  message: '新增成功!'
                })
                this.$store.commit('setPage', 1)
                this.$router.back()
              }
						})
					}
				})
			},
			open() {
				this.$confirm('是否取消新增', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.back()
				})
			},
			deleteform(index) {
				this.form.time_data.splice(index, 1)
			},
			addform() {
				if (this.form.time_data.length >= 10) {
					this.$message({
						type: 'warning',
						message: '最多只能添加10个时间段'
					})
				} else {
					this.form.time_data.push({
						name: '',
						start_time: '',
						end_time: ''
					})
				}
			},
			StartTime(index) {
				if (this.form.time_data[index].end_time) {
					if (this.form.time_data[index].start_time > this.form.time_data[index].end_time) {
						this.form.time_data[index].start_time = ''
						this.$message.error('开始时间不能大于结束时间')
					}
				}
			},
			EndTime(index) {
				if (this.form.time_data[index].end_time < this.form.time_data[index].start_time) {
					this.form.time_data[index].end_time = ''
					this.$message.error('结束时间必须大于开始时间')
				}
			},
			//  小时转化为分钟方法
			timeConversion(time) {
				let timeArr = time.split(':')
				let timeNum = parseInt(timeArr[0]) * 60 + parseInt(timeArr[1])
				return timeNum
			},
		},

		created() {
			this.$_axios2.get('api/1v1/coach/get-task-cycle').then(res => {
				this.campusArr = res.data.data
			})
			this.$_axios.get('site/school').then(res => {
				this.schoolArr = res.data.data
			})
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		margin-top: 20rem;
		padding: 20rem;
	}
	.container-align {
		padding-top: 20rem;
		background-color: #f2f5ff;
		display: flex;
		border-radius: 14rem;
		.form_item {
			width: 12vw !important;
		}
	}
	@media (max-width: 1322rem) {
		.container {
			width: 1000rem !important;
		}
	}
</style>
